import { useEffect, useState } from "react";

export const useMedia = () => {
  const [media, setMedia] = useState<number|undefined>(undefined);

  useEffect(() => {
    const bp1 = window.matchMedia("(max-width: 540px)");
    const bp2 = window.matchMedia("(max-width: 1079px)");
    const listener = () => setMedia(bp1.matches ? 0 : bp2.matches ? 1 : 2)
    setMedia(bp1.matches ? 0 : bp2.matches ? 1 : 2)
    bp1.addEventListener("change", listener);
    bp2.addEventListener("change", listener);
    return () => {
      bp1.removeEventListener("change", listener);
      bp2.removeEventListener("change", listener);
    }
  }, []);

  return media;
}

export const usePC = () => {
  const [isPC, setIsPC] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia("(min-width: 1080px)");
    const listener = () => setIsPC(mediaQueryList.matches);
    setIsPC(mediaQueryList.matches);
    mediaQueryList.addEventListener("change", listener);
    return () => mediaQueryList.removeEventListener("change", listener);
  }, []);

  return isPC;
};