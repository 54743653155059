import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Home({ href, text, color }: {
  href: string,
  text: string,
  color?: string,
}) {
  const [hovering, setHovering] = useState(false)
  const navigate = useNavigate()
  const color_ = color ?? 'var(--accent)'

  return (
    <button className="b16 pointer" style={{
      width: 190,
      height: 40,
      borderRadius: 40,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: color_,
      color: hovering ? 'var(--background)' : color_,
      background: hovering ? color_ : 'var(--background)',
    }} onMouseEnter={() => {
      setHovering(true) 
    }} onMouseLeave={() => {
      setHovering(false)
    }} onClick={() => {
      navigate(href)
    }}>{text}</button>
  );
}
