import L from 'leaflet'
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from 'react';
import { MapEvents, BlogMarker, CurrentMarker } from './MapComponents';

export default function Home({ blog }: { 
  blog: Blog,
}) {
  const [zoom, setZoom] = useState(12)
  const [current, setCurrent] = useState<L.LatLng|undefined>(undefined)
  const center = new L.LatLng(35.4660, 139.622) // 横浜駅
  const bounds = new L.LatLngBounds(
    new L.LatLng(50.5692, 149.897),
    new L.LatLng(17.0987, 126.298),
  )
  
  useEffect(() => {
    const watcher = navigator.geolocation.watchPosition((pos) => {
      setCurrent(new L.LatLng(pos.coords.latitude, pos.coords.longitude))
    })
    return () => navigator.geolocation.clearWatch(watcher)
  }, [])

  return (
    <MapContainer
      className='single-map'
      center={center}
      maxBounds={bounds}
      zoom={zoom}
      zoomControl={false}
      doubleClickZoom={false}
    >
      <MapEvents onZoomEnd={setZoom} />
      <TileLayer
        attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>"
        url='https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
      />
      { <BlogMarker blog={blog} /> }
      { current && <CurrentMarker location={current} /> }
    </MapContainer>
  );
}
