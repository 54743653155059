import { Helmet } from "react-helmet";
import GridCards from "../components/GridCards";
import PagingButton from "../components/PagingButton";
import RoundedButton from "../components/RoundedButton";
import { getBlogsByQuery, getBlogsCountByQuery } from "../features/db";
import { useMedia } from "../features/useMediaQuery";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function Home() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [blogs, setBlogs] = useState<Blog[]>([])
  const [count, setCount] = useState(0)
  const media = useMedia()
  const grids = media == 2 ? 12 : media == 1 ? 10 : 5

  const query = searchParams.get('q') ?? ''
  const page = Math.max(1, parseInt(searchParams.get('page') ?? '1'))
  const pages = Math.ceil(count / grids)
  
  useEffect(() => {
    if (media != undefined) {
      (async () => {
        setBlogs([])
        const blogs = await getBlogsByQuery(query, grids, (page - 1) * grids)
        const count = await getBlogsCountByQuery(query)
        setBlogs(blogs)
        setCount(count)
      })()
    }
  }, [media, query, page])

  return (
    <div className="col center" style={{ gap: 60 }}>
      <Helmet>
        <title>あるっこMap｜検索結果</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <GridCards blogs={blogs} />
      <div className="col center" style={{ gap: 40 }}>
        <div className="secondary-dashed" style={{ width: 300 }} />
          <div className="row" style={{ gap: 15 }}>
            {
              [...Array(4)].map((_, i) => {
                const p = page <= pages - 3 ? i + Math.max(1, page - 1) : i + Math.max(1, pages - 3)
                return p <= pages && <PagingButton key={i} text={p.toString()} active={page == p} onClick={() => {
                  setSearchParams({ page: p.toString() })
                }} />
              })
            }
          </div>
        <RoundedButton href='/' text="TOPへ" />
      </div>
    </div>
  );
}
