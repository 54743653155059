import { getAllCategories } from "../features/db";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePC } from "../features/useMediaQuery";

export default function Home() {
  const [categories, setCategories] = useState<Category[]>([])
  const pc = usePC()
  
  useEffect(() => {
    (async () => {
      const categories = await getAllCategories()
      setCategories(categories)
    })()
  }, [])
  
  return pc ? (
    <footer className="footer col b16">
      <div className="full row top between" style={{ overflowY: 'hidden' }}>
        <div className="col left g-10" style={{ width: 350 }}>
          <div className="b24">あるっこMap</div>
          <p className="r14">散歩好きのメンバーが、「いつもの道がもっと楽しくなる散歩の視点を届ける」をテーマに活動する団体。主な活動内容は、散歩イベントの開催・Instagramで散歩マガジンの更新、民間企業や行政さん向けに歩きたくなるまちづくりの助言など。まち歩きを楽しむ視点が身につくと、いつも通る道も、色鮮やかでワクワクする道になります、そんな道がたくさん増えたらいいな、という思いで活動中。</p>
        </div>
        <div className="col right between nowrap" style={{ width: '70%' }}>
          <div className="col g-30">
            <div className="row center g-20">
              <ul className="row center b16 g-20">
                <li className="pointer slash"><Link to='/'>トップ</Link></li>
                {
                  categories.map((category) => {
                    return (
                      <li key={category.id} className="pointer slash">
                        <Link to={`/categories/${category.id}`}>{category.name}</Link>
                      </li>
                    )
                  })
                }
                <li className="pointer slash"><Link to='/about'>あるっこMapとは？</Link></li>
              </ul>
            </div>
            <div className="reverse-dashed" />
            <div className="w-100 row center between">
              <div className="row center g-20">
                <a href="https://sahanzi.com">
                  <img className="pointer up" src="/x_icon.svg" width="30" height="30" alt="x.com" />
                </a>
                <a href="https://sahanzi.com">
                  <img className="pointer up" src="/instagram_icon.svg" width="30" height="30" alt="instagram.com" />
                </a>
                <a href="https://sahanzi.com">
                  <img className="pointer up" src="/facebook_icon.svg" width="30" height="30" alt="facebook.com" />
                </a>
              </div>
              <Link className="pointer" to='/privacy-policy'>プライバシーポリシー</Link>
            </div> 
          </div> 
        </div>
      </div>
      <p style={{ position: 'absolute', bottom: 30, right: 30 }}>© 2024 あるっこ Map</p>
    </footer>
  ) : (
    <footer className="footer col left g-30 b14">
      <div className="row center g-20">
        <a href="https://sahanzi.com">
          <img className="pointer up" src="/x_icon.svg" width="30" height="30" alt="x.com" />
        </a>
        <a href="https://sahanzi.com">
          <img className="pointer up" src="/instagram_icon.svg" width="30" height="30" alt="instagram.com" />
        </a>
        <a href="https://sahanzi.com">
          <img className="pointer up" src="/facebook_icon.svg" width="30" height="30" alt="facebook.com" />
        </a>
      </div>
      <div className="reverse-dashed" />
      <div className="col left g-10">
        <div className="b24">あるっこMap</div>
        <p>散歩好きのメンバーが、「いつもの道がもっと楽しくなる散歩の視点を届ける」をテーマに活動する団体。主な活動内容は、散歩イベントの開催・Instagramで散歩マガジンの更新、民間企業や行政さん向けに歩きたくなるまちづくりの助言など。まち歩きを楽しむ視点が身につくと、いつも通る道も、色鮮やかでワクワクする道になります、そんな道がたくさん増えたらいいな、という思いで活動中。</p>
      </div>
      <div className="reverse-dashed" />
      <div className="col left w-100 g-10">
        <Link className="pointer" to='/about'>あるっこMapとは？</Link>
        <Link className="pointer" to='/privacy-policy'>プライバシーポリシー</Link>
      </div>
      <p style={{ marginTop: 80 }}>© 2024 あるっこ Map</p>
    </footer>
  );
}