import { Link } from "react-router-dom";
import { useMedia } from "../features/useMediaQuery";

/* sp,tb only */
export default function CardR({ blog, onClick }: { blog: Blog, onClick: () => void }) {
  const media = useMedia()
  const title = blog.title ?? 'No title'
  const content = blog.content?.replaceAll(/<[^<>]*>/g, '') ?? ''
  const color = blog.category?.color ?? 'var(--theme)'
  const url = blog.eyecatch?.url ?? '/no_image.svg'
  return (
    <div className="w-100 row top g-5">
      <div className="w-100 col left g-5" style={{ padding: '5px 0 0'}}>
        <div className="pointer" onClick={onClick}>
          <h3 className="b16 l-2 pointer" style={{ color: color }}>{title}</h3>
        </div>
        <div className={`r14 lh-1 ${media == 0 ? 'l-2' : 'l-4'}`}>{content}</div>
      </div>
      <div className="pointer" onClick={onClick}>
        <img className="eyecatch" src={url} alt={title} />
      </div>
    </div>
  )
}
