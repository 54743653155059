import { Helmet } from "react-helmet";
import { usePC } from "../features/useMediaQuery";

function SiteIcon() {
  return (
    <a className="accent-stroke pointer up" href="https://sahanzi.com" style={{ width: 217, height: 50 }}>
      <div className="full row center g-25">
        <img src="/site_icon.svg" width="30" height="30" alt="go to site" />
        <div className="accent b16">サイトはこちら</div>
      </div>
    </a>
  )
}

function SNSIcons() {
  return (
    <div className="row center g-20">
      <a href="https://sahanzi.com">
        <img className="pointer up" src="/x_icon_black.svg" width="30" height="30" alt="x.com" />
      </a>
      <a href="https://sahanzi.com">
        <img className="pointer up" src="/instagram_icon_black.svg" width="30" height="30" alt="instagram.com" />
      </a>
      <a href="https://sahanzi.com">
        <img className="pointer up" src="/facebook_icon_black.svg" width="30" height="30" alt="facebook.com" />
      </a>
    </div>
  )
}

export default function Home() {
  const pc = usePC()
  return (
    <div className="r18 col g-80">
      <Helmet>
        <title>あるっこMap｜あるっこMapとは？</title>
        <meta name="description" content="散歩好きのメンバーが、「いつもの道がもっと楽しくなる散歩の視点を届ける」をテーマに活動する団体。主な活動内容は、散歩イベントの開催・Instagramで散歩マガジンの更新、民間企業や行政さん向けに歩きたくなるまちづくりの助言など。まち歩きを楽しむ視点が身につくと、いつも通る道も、色鮮やかでワクワクする道になります、そんな道がたくさん増えたらいいな、という思いで活動中。" />
      </Helmet>
      {
        pc ? <>
          <h1 className="accent b48 lh-2 accent-border t-center">あるっこMapについて</h1>
          <div className="w-100 row g-80">
            <div className="col g-60" style={{ width: '40%' }}>
              <div className="w-100 row center g-25">
                <div className="theme b16 nowrap">運営会社</div>
                <div className="secondary-dashed" />
              </div>
              <img className="w-100" src="/site_logo.svg" alt="arukko logo" />
            </div>
            <div className="col g-20" style={{ width: '60%' }}>
              <h2 className="b48 theme">一般社団法人あるっこ</h2>
              <p className="lh-2">
                散歩好きのメンバーが、「いつもの道がもっと楽しくなる散歩の視点を届ける」をテーマに活動する団体。主な活動内容は、散歩イベントの開催・Instagramで散歩マガジンの更新、民間企業や行政さん向けに歩きたくなるまちづくりの助言など。まち歩きを楽しむ視点が身につくと、いつも通る道も、色鮮やかでワクワクする道になります、そんな道がたくさん増えたらいいな、という思いで活動中。
              </p>
              <div className="row start g-40">
                <SiteIcon />
                <SNSIcons />
              </div>
            </div>
          </div>
        </> : <>
          <h1 className="accent b24 lh-2 accent-border t-center">あるっこMapについて</h1>
          <div className="w-100 col center g-25">
            <div className="w-100 row center g-25">
              <div className="theme b16 nowrap">運営会社</div>
              <div className="secondary-dashed" />
            </div>
            <img className="w-100" src="/site_logo.svg" alt="arukko logo" style={{ padding: '25px 0'}} />
            <h2 className="b24 theme">一般社団法人あるっこ</h2>
            <p className="lh-2">
              散歩好きのメンバーが、「いつもの道がもっと楽しくなる散歩の視点を届ける」をテーマに活動する団体。主な活動内容は、散歩イベントの開催・Instagramで散歩マガジンの更新、民間企業や行政さん向けに歩きたくなるまちづくりの助言など。まち歩きを楽しむ視点が身につくと、いつも通る道も、色鮮やかでワクワクする道になります、そんな道がたくさん増えたらいいな、という思いで活動中。
            </p>
            <SNSIcons />
            <SiteIcon />
          </div>
        </>
      }
    </div>
  )
}
