import { usePC } from "../features/useMediaQuery";
import { Link } from "react-router-dom";

export default function CardM({ blog }: { blog: Blog }) {
  const pc = usePC()
  const date = new Date(blog.publishedAt)

  const title = blog.title ?? 'No title'
  const content = blog.content?.replaceAll(/<[^<>]*>/g, '') ?? ''
  const color = blog.category?.color ?? 'var(--theme)'
  const url = blog.eyecatch?.url ?? '/no_image.svg'
  return pc ? (
    <div className="w-100 col" style={{ gap: 15 }}>
      <Link className="w-100" to={`/blogs/${blog.id}`}>
        <img className="eyecatch" src={url} alt={title} />
      </Link>
      <Link to={`/blogs/${blog.id}`}>
        <h3 className="b16 l-2 pointer" style={{ color: color }}>{title}</h3>
      </Link>
      <div className="r14 l-2 lh-1">{content}</div>
    </div>
  ) : (
    <div className="w-100 col left" style={{ gap: 10 }}>
      <Link className="w-100" to={`/blogs/${blog.id}`}>
        <img className="eyecatch" src={url} alt={title} />
      </Link>
      <div className="r10 secondary">{date.toLocaleDateString()}</div>
      <Link to={`/blogs/${blog.id}`}>
        <h3 className="b14 l-1 pointer" style={{ color: color }}>{title}</h3>
      </Link>
      <div className="r14 l-3 lh-1">{content}</div>
    </div>
  )
}
