import { usePC } from "../features/useMediaQuery";
import CardM from "./CardM";

export default function Home({ blogs }: { blogs: Blog[] }) {
  const pc = usePC()

  return pc ? (
    <div className="row g-50">
      {
        [...Array(4)].map((_, i) => (
            <div key={i} className="w-100">
              { blogs.length > i && <CardM blog={blogs[i]} /> }
            </div>
        ))
      }
    </div>
  ) : (
    <div className="row scroll-x g-15">
      {
        [...Array(4)].map((_, i) => (
            <div key={i} style={{ minWidth: '56%' }}>
              { blogs.length > i && <CardM blog={blogs[i]} /> }
            </div>
        ))
      }
    </div>
  );
}
