import { Helmet } from "react-helmet";
import CategoryPreview from "../components/CategoryPreview";
import TopCards from "../components/TopCards";
import { getTopBlogs, getAllCategories } from "../features/db";
import { useState, useEffect } from "react";

export default function Home() {
  const [categories, setCategories] = useState<Category[]>([])
  const [blogs, setBlogs] = useState<Blog[]>([])
  
  useEffect(() => {
    (async () => {
      const categories = await getAllCategories()
      setCategories(categories)
    })()
  }, [])
  
  useEffect(() => {
    (async () => {
      const blogs = await getTopBlogs(5)
      setBlogs(blogs)
    })()
  }, [])

  return (
    <div className="col">
      <Helmet>
        <title>あるっこ Map｜いつもの道がもっと楽しくなる散歩の視点を届ける</title>
        <meta name="description" content="散歩好きのメンバーが、「いつもの道がもっと楽しくなる散歩の視点を届ける」をテーマに活動する団体。主な活動内容は、散歩イベントの開催・Instagramで散歩マガジンの更新、民間企業や行政さん向けに歩きたくなるまちづくりの助言など。まち歩きを楽しむ視点が身につくと、いつも通る道も、色鮮やかでワクワクする道になります、そんな道がたくさん増えたらいいな、という思いで活動中。" />
      </Helmet>
      <div className="col" style={{ gap: 200 }}>
        <TopCards title="新着記事" color="var(--accent)" blogs={blogs} />
        <div className="col" style={{ gap: 100 }}>
          {
            categories.map((category) => <CategoryPreview key={category.id} category={category} />)
          }
        </div>
      </div>
    </div>
  );
}
