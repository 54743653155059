import { useEffect, useRef, useState } from "react";
import { usePC } from "../features/useMediaQuery";
import { getBlogByID, getBlogsByIDs } from "../features/db";
import CardS from "./CardS";
import CardR from "./CardR";
import RoundedButton from "./RoundedButton";
import { useNavigate } from "react-router-dom";

function BlogExcerpt({ blog }: { blog: Blog }) {
  const pc = usePC()
  const navigate = useNavigate()
  const title = blog.title ?? 'No title'
  const content = blog.content?.replaceAll(/<[^<>]*>/g, '') ?? ''
  const color = blog.category?.color ?? 'var(--theme)'
  const url = blog.eyecatch?.url ?? '/no_image.svg'
  const address = blog.location?.address ?? ''
  return pc ? (
    <>
      <img className="eyecatch" src={url} alt={title} />
      <h3 className="w-100 b16 l-1" style={{ color: color }}>{title}</h3>
      <a className="w-100 accent-stroke pointer up" href={`https://www.google.co.jp/maps/place/${encodeURIComponent(address)}`} target="_blank" style={{ height: 45 }}>
        <div className="full row center g-10">
          <img src="/pin_icon.svg" width="18" height="25" alt="check by google map" />
          <div className="accent b16">Googleマップで見る</div>
        </div>
      </a>
      <div className="w-100 clipped" style={{ flex: 1 }}>
        <div className="r14 lh-30">{content}</div>
      </div>
      <RoundedButton href={`/blogs/${blog.id}`} text="More..." color={color} />
    </>
  ) : (
    <>
      <CardR blog={blog} onClick={() => navigate(`/blogs/${blog.id}`)} />
      <div className="w-100 row between">
        <a className="accent-stroke pointer up" href="https://www.google.co.jp/maps/" style={{ width: 40, height: 40, borderRadius: 8 }}>
          <div className="full row center g-10">
            <img src="/pin_icon.svg" width="18" height="25" alt="check by google map" />
          </div>
        </a>
        <RoundedButton href={`/blogs/${blog.id}`} text="More..." color={color} />
      </div>
    </>
  )
}

export default function Home({ open, setOpen, nearby, setNearbyLimit, selection, setSelection }: {
  open: boolean|undefined,
  setOpen: (open: boolean) => void,
  nearby: Blog[],
  setNearbyLimit: (nearbyLimit: number) => void,
  selection: Blog|undefined
  setSelection: (blog: Blog) => void,
}) {
  const pc = usePC()
  const ref = useRef<HTMLDivElement>(null)
  const [blog, setBlog] = useState<Blog|undefined>(undefined)
  const [blogs, setBlogs] = useState<Blog[]>([])
  const [dragStarted, setDragStarted] = useState(0)
  const [dragging, setDragging] = useState(0)

  const status = open == undefined ? '' : open ? 'opened' : 'closed'
  const toggleFrame = [
    {
      transform: `translateY(clamp(0px, ${open ? '' : '100% - 82px + '}${dragging}px, 100% - 82px))`,
    },
    {
      transform: `translateY(calc(${!open ? '0px' : '100% - 82px'}))`,
    },
  ]

  const toggle = () => {
    if(!pc) {
      ref.current?.animate(toggleFrame, 200)
    }
    setOpen(!open)
  }

  const dragStart = (y: number) => {
    setDragStarted(y)
    setDragging(0)
  }

  const drag = (y: number) => {
    const dy = y - dragStarted
    setDragging(dy)
  }

  const dragEnd = () => {
    if(open && dragging > 100 || !open && dragging < -100) {
      ref.current?.animate(toggleFrame, 200)
      setOpen(!open)
    }
    setDragStarted(0)
    setDragging(0)
  }

  useEffect(() => {
    if(selection != undefined) {
      (async () => {
        const blog = await getBlogByID(selection.id)
        setBlog(blog)
        if(!open) {
          toggle()
        }
      })()
    } else {
      setBlog(undefined)
    }
  }, [selection])

  useEffect(() => {
    if(nearby.length > 0) {
      (async () => {
        const ids = nearby.map((blog) => blog.id)
        const blogs = await getBlogsByIDs(ids)
        const sorted = [] as Blog[]
        for(const n of nearby) {
          const found = blogs.find(b => b.id == n.id)
          if(found != undefined) {
            sorted.push(found)
          }
        }
        setBlogs(sorted)
      })()
    }
  }, [nearby])

  useEffect(() => {
    if(pc) {
      const sideBarHeight = ref.current?.clientHeight ?? 0
      const contentsHeight = sideBarHeight - 82 // 上下余白 + h2
      const limit = contentsHeight / 207 // gap + card height
      setNearbyLimit(limit)
    } else {
      setNearbyLimit(3)
    }
  }, [ref.current?.clientHeight, pc]);
  
  return pc ? (
    <div>
      <img 
        className={`nearby-sheet-opner ${status}`} 
        src={open ? '/opener_right.svg' : '/opener_left.svg'} 
        alt={open ? 'close nearby sheet' : 'open nearby sheet'} 
        onClick={toggle}
      />
      <div ref={ref} className={`nearby-sheet ${status} col h-100 start center g-15`}>
        {
          blog != undefined ? <>
            <h2 className="b16 accent">このPinに関してのエッセイ</h2>
            <BlogExcerpt blog={blog} />
          </> : <>
            <h2 className="b16 accent">この周辺に関してのエッセイ</h2>
            <div ref={ref} className="col h-100 between g-15" style={{ padding: '15px 0'}}>
              {
                blogs.map(blog =><CardS key={blog.id} blog={blog} onClick={() => setSelection(blog)} />)
              }
            </div>
          </>
        }
      </div>
    </div>
  ) : (
    <div>
      <div
        ref={ref} 
        className={`nearby-sheet ${status} col g-20`} 
        onTouchStart={e => dragStart(e.touches[0].pageY)}
        onTouchMove={e => drag(e.touches[0].pageY)}
        onTouchEnd={dragEnd}
        style={{
         transform: `translateY(clamp(0px, ${open ? '' : '100% - 82px + '}${dragging}px, 100% - 82px))`
        }}
      >
        <div className="w-100 col center g-10" onClick={toggle}>
          <img 
            className="nearby-sheet-opener"
            src={open ? '/opener_down.svg' : '/opener_up.svg'} 
            alt={open ? 'close nearby sheet' : 'open nearby sheet'} 
          />
          <h2 className="b16 accent">{blog != undefined ? 'このPinに関してのエッセイ' : 'この周辺に関してのエッセイ'}</h2>
        </div>
        {
          blog != undefined ? (
            <div className="col g-15">
              <div className="secondary-dashed" />
              <BlogExcerpt blog={blog} />
            </div>
          ) : (
            <div className="col g-10">
              {
                blogs.map((blog) => {
                  return <>
                    <div className="secondary-dashed" />
                    <CardR key={blog.id} blog={blog} onClick={() => setSelection(blog)} />
                  </>
                })
              }
            </div>
          )
        }
      </div>
    </div>
  );
}