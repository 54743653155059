import { CSSProperties, useState } from "react";

export default function Home({ style, onSubmit }: { style?: CSSProperties, onSubmit: (words: string) => void }) {
  const [words, setWords] = useState('')
  const [composing, setComposition] = useState(false)

  return (
    <div className="search-bar" style={style}>
      <img className="pointer" src="/search.svg" width="16" height="16" alt="search blogs" onClick={() => {
        if (words.length > 0) {
          onSubmit(words)
        }
      }} />
      <div className="accent-stroke" style={{ width: 0, height: 20 }} />
      <input className="w-100 accent b16" placeholder="SEARCH" value={words} onChange={(e) => { 
        setWords(e.target.value) 
      }} onCompositionStart={() => {
        setComposition(true)
      }} onCompositionEnd={() => {
        setComposition(false)
      }} onKeyDown={(e) => {
        if (e.key == 'Enter' && !composing && words.length > 0) {
          onSubmit(words)
        }
      }}/>
    </div>
  );
}
