import { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import SearchBar from "../components/SearchBar";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";

type Location = {
  status: string,
  data: {
    address: string,
    lat: number,
    lng: number
  }
}

export default function Home() {
  const [iframeId, setIframeId] = useState(null);
  const [location, setLocation] = useState<Location|undefined>(undefined);
  const [center, setCenter] = useState({ lat: 35.69575, lng: 139.77521 });
  const [zoom, setZoom] = useState(12);

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (
        e.isTrusted === true && 
        e.data.action === "MICROCMS_GET_DEFAULT_DATA"
      ) {
        setIframeId(e.data.id)
        if (e.data.message) {
          setLocation({ 
            status: "default",
            data: e.data.message.data,
          })
          setCenter({
            lat: e.data.message.data.lat,
            lng: e.data.message.data.lng,
          })
          setZoom(16)
        }
      } else if (
        e.isTrusted === true &&
        e.data.action === "MICROCMS_POST_DATA_SUCCESS"
      ) {
        toast.remove()
        toast.success("microCMSにデータを連携しました")
      }
    });
  }, []);
  
  useEffect(() => {
    window.parent.postMessage(
      {
        id: iframeId,
        action: "MICROCMS_UPDATE_STYLE",
        message: {
          height: 640,
          width: "100%",
        },
      },
      "https://arukko-map.microcms.io"
    );
  }, [iframeId]);

  useEffect(() => {
    if (location != undefined && location.status === "update") {
      window.parent.postMessage(
        {
          id: iframeId,
          action: "MICROCMS_POST_DATA",
          message: {
            data: location.data,
          },
        },
        "https://arukko-map.microcms.io"
      );
    }
  }, [location]);

  return (
    <div className="relative">
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="absolute" style={{ zIndex: 1, top: 10, left: 200 }}>
        <SearchBar onSubmit={(words) => {
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ address: words }, (results, status) => {
            const res = results?.shift()
            if (status === "OK" && res != undefined) {
              setLocation({
                status: "update",
                data: {
                  address: res.formatted_address,
                  lat: res.geometry.location.lat(),
                  lng: res.geometry.location.lng(),
                },
              })
              setCenter({
                lat: res.geometry.location.lat(),
                lng: res.geometry.location.lng(),
              })
              setZoom(16)
            }
          })
        }} />
      </div>
      <LoadScript googleMapsApiKey="AIzaSyDiJ_KM0gqRMySOdKbJQg5Fse_DNI6x-N4">
        <GoogleMap 
          onClick={(e) => {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: e.latLng }, (results, status) => {
              const res = results?.shift()
              if (status === "OK" && res != undefined) {
                setLocation({
                  status: "update",
                  data: {
                    address: res.formatted_address,
                    lat: res.geometry.location.lat(),
                    lng: res.geometry.location.lng(),
                  },
                })
              }
            })
          }}
          mapContainerStyle={{ width: "100%", height: "100vh" }}
          center={center}
          zoom={zoom}
        >
          {location && <Marker position={location.data} />}
        </GoogleMap>
      </LoadScript>
      <Toaster position="top-right" toastOptions={{ duration: 1000 }} />
    </div>
  );
}