import axios from "axios"

export async function getAllCategories() {
  const res = await axios.get('https://arukko-map.microcms.io/api/v1/categories?limit=100', {
    headers: { 'X-MICROCMS-API-KEY': 'NaDJETMt28G1oMqFn7xxpuXZ46miuyw9rkrF' },
  })
  return res.data.contents as Category[]
}

export async function getCategoryByID(id: string) {
  const res = await axios.get(`https://arukko-map.microcms.io/api/v1/categories/${id}`, {
    headers: { 'X-MICROCMS-API-KEY': 'NaDJETMt28G1oMqFn7xxpuXZ46miuyw9rkrF' },
  })
  return res.data as Category
}

export async function getBlogsByCategory(categoryID: string, limit?: number, offset?: number) {
  const res = await axios.get(`https://arukko-map.microcms.io/api/v1/blogs?limit=${limit ?? 100}&offset=${offset ?? 0}&filters=category[equals]${categoryID}`, {
    headers: { 'X-MICROCMS-API-KEY': 'NaDJETMt28G1oMqFn7xxpuXZ46miuyw9rkrF' },
  })
  return res.data.contents as Blog[]
}

export async function getBlogsCountByCategory(categoryID: string) {
  const res = await axios.get(`https://arukko-map.microcms.io/api/v1/blogs?limit=0&filters=category[equals]${categoryID}`, {
    headers: { 'X-MICROCMS-API-KEY': 'NaDJETMt28G1oMqFn7xxpuXZ46miuyw9rkrF' },
  })
  return res.data.totalCount as number
}

export async function getBlogsByQuery(query: string, limit?: number, offset?: number) {
  const res = await axios.get(`https://arukko-map.microcms.io/api/v1/blogs?limit=${limit ?? 100}&offset=${offset ?? 0}&q=${query}`, {
    headers: { 'X-MICROCMS-API-KEY': 'NaDJETMt28G1oMqFn7xxpuXZ46miuyw9rkrF' },
  })
  return res.data.contents as Blog[]
}

export async function getBlogsCountByQuery(query: string) {
  const res = await axios.get(`https://arukko-map.microcms.io/api/v1/blogs?limit=0&q=${query}`, {
    headers: { 'X-MICROCMS-API-KEY': 'NaDJETMt28G1oMqFn7xxpuXZ46miuyw9rkrF' },
  })
  return res.data.totalCount as number
}

export async function getAllBlogs() {
  const res = await axios.get(`https://arukko-map.microcms.io/api/v1/blogs?fields=id,title,eyecatch,category.color,priority,location&limit=100`, {
    headers: { 'X-MICROCMS-API-KEY': 'NaDJETMt28G1oMqFn7xxpuXZ46miuyw9rkrF' },
  })
  const contents = res.data.contents as Blog[]
  const total = res.data.totalCount
  let offset = 100
  while(offset < total) {
    const res = await axios.get(`https://arukko-map.microcms.io/api/v1/blogs?fields=id,title,eyecatch,category.color,priority,location&limit=100&offset=${offset}`, {
      headers: { 'X-MICROCMS-API-KEY': 'NaDJETMt28G1oMqFn7xxpuXZ46miuyw9rkrF' },
    })
    contents.concat(res.data.contents)
    offset += 100
  }
  return contents
}

export async function getTopBlogs(limit: number) {
  const res = await axios.get(`https://arukko-map.microcms.io/api/v1/blogs?limit=${limit}`, {
    headers: { 'X-MICROCMS-API-KEY': 'NaDJETMt28G1oMqFn7xxpuXZ46miuyw9rkrF' },
  })
  return res.data.contents as Blog[]
}

export async function getBlogsByIDs(listOfBlogID: string[]) {
  const ids = listOfBlogID.filter(id => id != '').join(',')
  const res = await axios.get(`https://arukko-map.microcms.io/api/v1/blogs?ids=${ids}`, {
    headers: { 'X-MICROCMS-API-KEY': 'NaDJETMt28G1oMqFn7xxpuXZ46miuyw9rkrF' },
  })
  return res.data.contents as Blog[]
}

export async function getBlogByID(blogID: string) {
  const res = await axios.get(`https://arukko-map.microcms.io/api/v1/blogs/${blogID}`, {
    headers: { 'X-MICROCMS-API-KEY': 'NaDJETMt28G1oMqFn7xxpuXZ46miuyw9rkrF' },
  })
  return res.data as Blog
}
