import L from 'leaflet'
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from 'react';
import { MapEvents, BlogMarker, CurrentMarker, FocusedMarker } from './MapComponents';

export default function Home({ blogs, setOpen, nearbyLimit, setNearby, selection, setSelection }: { 
  blogs: Blog[],
  setOpen: (open: boolean) => void,
  nearbyLimit: number,
  setNearby: (blogs: Blog[]) => void,
  selection: Blog|undefined,
  setSelection: (blog: Blog|undefined) => void
}) {
  const [zoom, setZoom] = useState(12)
  const [current, setCurrent] = useState<L.LatLng|undefined>(undefined)
  const [focused, setFocused] = useState<L.LatLng|undefined>(undefined)
  const center = new L.LatLng(35.4660, 139.622) // 横浜駅
  const bounds = new L.LatLngBounds(
    new L.LatLng(50.5692, 149.897),
    new L.LatLng(17.0987, 126.298),
  )
  
  useEffect(() => {
    const watcher = navigator.geolocation.watchPosition((pos) => {
      setCurrent(new L.LatLng(pos.coords.latitude, pos.coords.longitude))
    })
    return () => navigator.geolocation.clearWatch(watcher)
  }, [])

  useEffect(() => {
    const pointing = focused ?? current
    if(pointing != undefined) {
      const cx = pointing.lat
      const cy = pointing.lng
      const nearby = blogs.sort((a, b) => {
        const ax = a.location?.lat ?? 0
        const ay = a.location?.lng ?? 0
        const bx = b.location?.lat ?? 0
        const by = b.location?.lng ?? 0
        const disA = Math.sqrt((cx-ax)*(cx-ax)+(cy-ay)*(cy-ay))
        const disB = Math.sqrt((cx-bx)*(cx-bx)+(cy-by)*(cy-by))
        return disA - disB
      })
      setNearby(nearby.slice(0, nearbyLimit))
    } else {
      setNearby(blogs.slice(0, nearbyLimit))
    }
  }, [blogs, nearbyLimit, current, focused])

  return (
    <MapContainer
      className='main-map'
      center={center}
      maxBounds={bounds}
      zoom={zoom}
      zoomControl={false}
      doubleClickZoom={false}
    >
      <MapEvents onClick={(location) => {
        setSelection(undefined)
        setOpen(true)
      }} onDoubleClick={(location) => {
        setSelection(undefined)
        setFocused(location)
      }} onZoomEnd={setZoom} />
      <TileLayer
        attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>"
        url='https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
      />
      {
        blogs.map((blog) => {
          const priority = parseInt(blog.priority?.slice(0, 1)?.shift()?.slice(0, 1) ?? '1')
          if(priority == 1 || (priority + 10) < zoom) {
            return <BlogMarker key={blog.id} blog={blog} selected={blog == selection} onClick={() => {
              setSelection(blog)
              setFocused(undefined)
              setOpen(true)
            }} />
          }
        })
      }
      { current && <CurrentMarker location={current} /> }
      { focused && <FocusedMarker location={focused} /> }
    </MapContainer>
  );
}
