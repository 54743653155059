import { usePC } from "../features/useMediaQuery";
import { Link } from "react-router-dom";

export default function CardS({ blog, onClick }: { blog: Blog, onClick: () => void }) {
  const pc = usePC()
  const date = new Date(blog.publishedAt)

  const title = blog.title ?? 'No title'
  const content = blog.content?.replaceAll(/<[^<>]*>/g, '') ?? ''
  const color = blog.category?.color ?? 'var(--theme)'
  const url = blog.eyecatch?.url ?? '/no_image.svg'
  return pc ? (
    <div className="w-100 col g-10">
      <div className="w-100 pointer" onClick={onClick}>
        <img className="eyecatch" src={url} alt={title} />
      </div>
      <div className="pointer" onClick={onClick}>
        <h3 className="b16 l-1 lh-1 pointer" style={{ color: color }}>{title}</h3>
      </div>
      <div className="r14 l-2 lh-1">{content}</div>
    </div>
  ) : (
    <div className="w-100 col left g-10">
      <div className="w-100 pointer" onClick={onClick}>
        <img className="eyecatch" src={url} alt={title} />
      </div>
      <div className="r10 secondary">{date.toLocaleDateString()}</div>
      <div className="pointer" onClick={onClick}>
        <h3 className="b16 l-1 lh-1 pointer" style={{ color: color }}>{title}</h3>
      </div>
      <div className="r14 l-2 lh-1">{content}</div>
    </div>
  )
}
