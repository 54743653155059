import TopCards from "../components/TopCards";
import GridCards from "../components/GridCards";
import PagingButton from "../components/PagingButton";
import RoundedButton from "../components/RoundedButton";
import { getBlogsCountByCategory, getBlogsByCategory, getCategoryByID } from "../features/db";
import { useMedia } from "../features/useMediaQuery";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Home() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [category, setCategory] = useState<Category|undefined>(undefined)
  const [blogs, setBlogs] = useState<Blog[]>([])
  const [count, setCount] = useState(0)
  const [notFound, setNotFound] = useState(false)
  const { id } = useParams<{ id: string|undefined }>()

  const media = useMedia()
  const tops = media == 2 ? 17 : media == 1 ? 11 : 5
  const grids = media == 2 ? 12 : media == 1 ? 10 : 5

  const page = Math.max(1, parseInt(searchParams.get('page') ?? '1'))
  const pages = (count - tops) > 0 ? Math.ceil((count - tops) / grids + 1) : 1
  
  useEffect(() => {
    if (id != undefined) {
      (async () => {
        const category = await getCategoryByID(id)
        setCategory(category)
        setNotFound(category == undefined)
      })()
    }
  }, [id])

  useEffect(() => {
    if (category != undefined && media != undefined) {
      (async () => {
        setBlogs([])
        const blogs = await getBlogsByCategory(category.id, page > 1 ? grids : tops, page > 1 ? tops + (page - 2) * grids : 0)
        const count = await getBlogsCountByCategory(category.id)
        setBlogs(blogs)
        setCount(count)
      })()
    }
  }, [category, media, page])

  if(category != undefined) {
    const title = category.name ?? 'No title'
    const color = category.color ?? 'var(--theme)'
    return (
      <div className="col center" style={{ gap: 60 }}>
        <Helmet>
          <title>あるっこMap｜{title}</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        {
          category && page == 1 ? <>
            <TopCards title={title} color={color} blogs={blogs.slice(0, 5)} />
            <GridCards blogs={blogs.slice(5)} />
          </> : <>
            <GridCards blogs={blogs} />
          </>
        }
        <div className="col center" style={{ gap: 40 }}>
          <div className="secondary-dashed" style={{ width: 300 }} />
          <div className="row" style={{ gap: 15 }}>
            {
              category && [...Array(4)].map((_, i) => {
                const p = page <= pages - 3 ? i + Math.max(1, page - 1) : i + Math.max(1, pages - 3)
                return p <= pages && <PagingButton key={i} text={p.toString()} active={page == p} onClick={() => {
                  setSearchParams({ page: p.toString() })
                }} />
              })
            }
          </div>
          <RoundedButton href='/' text="TOPへ" />
        </div>
      </div>
    );
  } else if(notFound) {
    return (
      <div className="col center" style={{ gap: 60 }}>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div>カテゴリーが見つかりませんでした</div>
        <div className="col center" style={{ gap: 40 }}>
          <div className="secondary-dashed" style={{ width: 300 }} />
          <RoundedButton href='/' text="TOPへ" />
        </div>
      </div>
    )
  } else {
    return null
  }
}
