import { useMedia } from "../features/useMediaQuery";
import CardM from "./CardM";

export default function Home({ blogs }: { blogs: Blog[] }) {
  const media = useMedia()

  return media == 2 ? (
    <div className="row" style={{ flexWrap: 'wrap', gap: 50 }}>
      {
        blogs.map((blog) => (
          <div key={blog.id} style={{ width: 'calc(25% - 150px / 4)' }}><CardM blog={blog} /></div>
        ))
      }
    </div>
  ) : media == 1 ? (
    <div className="row" style={{ flexWrap: 'wrap', gap: 50 }}>
      {
        blogs.map((blog) => (
          <div key={blog.id} style={{ width: 'calc(50% - 50px / 2)' }}><CardM blog={blog} /></div>
        ))
      }
    </div>
  ) : (
    <div className="col" style={{ gap: 15 }}>
      {
        blogs.map((blog, i) => (
          <>{ i > 0 && <div className="secondary-dashed" /> }<CardM blog={blog} /></>
        ))
      }
    </div>
  );
}
