import Map from "./components/Map";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TopPage from "./routes/TopPage";
import AboutPage from "./routes/AboutPage";
import BlogPage from "./routes/BlogPage";
import BlogsPage from "./routes/BlogsPage";
import CategoryPage from "./routes/CategoryPage";
import PrivacyPolicyPage from "./routes/PrivacyPolicyPage";
import LocationPickerPage from "./routes/LocationPickerPage";
import { BrowserRouter, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { initializeApp } from 'firebase/app';
import { useEffect, useState } from "react";
import { getAllBlogs, getBlogsByIDs } from "./features/db";
import NearbySheet from "./components/NearbySheet";
import NearbyMap from "./components/NearbyMap";
import RowCards from "./components/RowCards";
import RoundedButton from "./components/RoundedButton";
import { usePC } from "./features/useMediaQuery";

initializeApp({
  apiKey: "AIzaSyCbS44SocfTUyaESjopKGX9eoEyu8Kdnvo",
  authDomain: "sample-51600.firebaseapp.com",
  projectId: "sample-51600",
  storageBucket: "sample-51600.appspot.com",
  messagingSenderId: "783851489708",
  appId: "1:783851489708:web:37a1cd969776fe78611209"
});

function App() {
  return (
    <BrowserRouter>
      <Observer />
      <Routes>
        <Route element={<TopLayout />}>
          <Route index element={<TopPage />} />
        </Route>
        <Route path='/' element={<ContentLayout />}>
          <Route path='/about' element={<AboutPage />} />
          <Route path='/blogs' element={<BlogsPage />} />
          <Route path='/blogs/:id' element={<BlogPage />} />
          <Route path='/categories/:id' element={<CategoryPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
        </Route>
        <Route path='/microcms.location-picker' element={<LocationPickerPage />} />
      </Routes>
    </BrowserRouter>
  )
}

function Observer() {
  const { pathname, search } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname, search])

  return null
}

function TopLayout() {
  const pc = usePC()
  const [blogs, setBlogs] = useState<Blog[]>([])
  const [open, setOpen] = useState<boolean|undefined>(undefined)
  const [nearby, setNearby] = useState<Blog[]>([])
  const [nearbyLimit, setNearbyLimit] = useState<number>(0)
  const [selection, setSelection] = useState<Blog|undefined>(undefined)
  
  useEffect(() => {
    (async () => {
      const blogs = await getAllBlogs()
      console.log(blogs)
      setBlogs(blogs)
    })()
  }, [])

  return (
    <>
      <div className="relative clipped">
        <Map blogs={blogs} setOpen={setOpen} nearbyLimit={nearbyLimit} setNearby={setNearby} selection={selection} setSelection={setSelection} />
        <NearbySheet open={open} setOpen={setOpen} nearby={nearby} setNearbyLimit={setNearbyLimit} selection={selection} setSelection={setSelection} />
        { pc && <Header /> }
      </div>
      { !pc && <Header /> }
      <main className="relative">
        <div className="container">
          <Outlet />
        </div>
        <a className="sahanzi-logo pointer" href="https://sahanzi.com">
          <img src="/sahanzi_logo.svg" alt="created by sahanzi" width="106" height="12" />
        </a>
      </main>
      <Footer />
    </>
  )
}

function ContentLayout() {
  const pc = usePC()
  const [blogs, setBlogs] = useState<Blog[]>([])
  const [nearby, setNearby] = useState<Blog[]>([])
  const [nearbyDetails, setNearbyDetails] = useState<Blog[]>([])
  
  useEffect(() => {
    (async () => {
      const blogs = await getAllBlogs()
      console.log(blogs)
      setBlogs(blogs)
    })()
  }, [])

  useEffect(() => {
    if(nearby.length > 0) {
      (async () => {
        const ids = nearby.map((blog) => blog.id)
        const blogs = await getBlogsByIDs(ids)
        const sorted = [] as Blog[]
        for(const n of nearby) {
          const found = blogs.find(b => b.id == n.id)
          if(found != undefined) {
            sorted.push(found)
          }
        }
        setNearbyDetails(sorted)
      })()
    }
  }, [nearby])

  // 現在地わかってる場合：現在地中心で少しズームして近い順4つ
  // 現在地わかってない場合：デフォルトの中心で少し引きにして新着4つ
  return (
    <>
      <Header />
      <main className="relative">
        <div className="container">
          <Outlet />
          <div className="col center g-40" style={{ marginTop: 200 }}>
            <h1 className={`accent ${pc ? 'b48' : 'b24'} lh-2 accent-border t-center`}>その他の周辺情報</h1>
            <NearbyMap blogs={blogs} nearby={nearby} setNearby={setNearby} />
            <div className="accent-line" />
            <RowCards blogs={nearbyDetails} />
            <RoundedButton href="/" text="TOPへ" />
          </div>
        </div>
        <a className="sahanzi-logo pointer" href="https://sahanzi.com">
          <img src="/sahanzi_logo.svg" alt="created by sahanzi" width="106" height="12" />
        </a>
      </main>
      <Footer />
    </>
  )
}

export default App;
