import { getAllCategories } from "../features/db";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import { usePC } from "../features/useMediaQuery";

export default function Home() {
  const pc = usePC()
  const [categories, setCategories] = useState<Category[]>([])
  const [open, setOpen] = useState<boolean|undefined>(undefined)
  const navigate = useNavigate()

  const status = open == undefined ? '' : open ? 'opened' : 'closed'
  
  useEffect(() => {
    (async () => {
      const categories = await getAllCategories()
      setCategories(categories)
    })()
  }, [])
  
  return pc ? (
    <header className="header">
      <div className="full row center g-20 nowrap">
        <ul className="full row center g-20 start b16">
          <li className="pointer slash"><Link to='/'>トップ</Link></li>
          {
            categories.map((category) => (
              <li key={category.id} className="pointer slash">
                <Link to={`/categories/${category.id}`}>{category.name}</Link>
              </li>
            ))
          }
          <li className="pointer slash"><Link to='/about'>あるっこMapとは？</Link></li>
          <SearchBar style={{ maxWidth: 300 }} onSubmit={(words) => {
            navigate(`/blogs?q=${words}`)
          }} />
        </ul>
        <Link to='/' className="b24 pointer">あるっこMap</Link>
      </div>
    </header>
  ) : (
    <header className="header">
      <SearchBar onSubmit={(words) => {
        navigate(`/blogs?q=${words}`)
      }} />
      <img className="header-menu" src="/menu.svg" width="40" height="40" alt="header menu" onClick={() => {
        setOpen(true)
      }} />
      <div className={`header-cover ${status}`} onClick={() => setOpen(false)}>
        <div className="h-100 col center g-50" >
          <ul className="col center g-20 b16 nowrap">
            <li className="pointer slash"><Link to='/'>トップ</Link></li>
            {
              categories.map((category) => (
                <li key={category.id} className="pointer slash">
                  <Link to={`/categories/${category.id}`}>{category.name}</Link>
                </li>
              ))
            }
            <li className="pointer slash"><Link to='/about'>あるっこMapとは？</Link></li>
          </ul>
          <img className="pointer" src="/close.svg" width="25" height="25" alt="close menu" />
        </div>
      </div>
    </header>
  )
}