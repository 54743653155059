import { useMedia } from "../features/useMediaQuery";
import CardL from "./CardL";
import CardM from "./CardM";

export default function Home({ title, color, blogs }: { title: string, color: string, blogs: Blog[] }) {
  const media = useMedia()

  return media == 2 ? (
    <div className="col g-60">
      <h1 className="accent b48 lh-2 border t-center" style={{ color }}>{title}</h1>
      <div className="row g-50">
        <div className="w-100">
          { blogs.length > 0 && <CardL blog={blogs[0]} /> }
        </div>
        <div className="w-100 col g-50">
          <div className="row g-50">
            { blogs.length > 1 && <CardM blog={blogs[1]} /> }
            { blogs.length > 2 && <CardM blog={blogs[2]} /> }
          </div>
          <div className="row g-50">
            { blogs.length > 3 && <CardM blog={blogs[3]} /> }
            { blogs.length > 4 && <CardM blog={blogs[4]} /> }
          </div>
        </div>
      </div>
    </div>
  ) : media == 1 ? (
    <div className="col g-60">
      <h1 className="accent b48 lh-2 border t-center" style={{ color }}>{title}</h1>
      <div className="col g-50">
        { blogs.length > 0 && <CardL blog={blogs[0]} /> }
        <div className="row g-50">
          { blogs.length > 1 && <CardM blog={blogs[1]} /> }
          { blogs.length > 2 && <CardM blog={blogs[2]} /> }
        </div>
        <div className="row g-50">
          { blogs.length > 3 && <CardM blog={blogs[3]} /> }
          { blogs.length > 4 && <CardM blog={blogs[4]} /> }
        </div>
      </div>
    </div>
  ) : (
    <div className="col g-20">
      <h1 className="accent b24 lh-2 border t-center" style={{ color }}>{title}</h1>
      <div className="col g-15">
        { blogs.length > 0 && <CardL blog={blogs[0]} /> }
        { blogs.length > 1 && <><div className="secondary-dashed" /><CardM blog={blogs[1]} /></> }
        { blogs.length > 2 && <><div className="secondary-dashed" /><CardM blog={blogs[2]} /></> }
        { blogs.length > 3 && <><div className="secondary-dashed" /><CardM blog={blogs[3]} /></> }
        { blogs.length > 4 && <><div className="secondary-dashed" /><CardM blog={blogs[4]} /></> }
        { blogs.length > 5 && <><div className="secondary-dashed" /><CardM blog={blogs[5]} /></> }
      </div>
    </div>
  )
}