import RoundedButton from "./RoundedButton";
import RowCards from "./RowCards";
import { getBlogsByCategory } from "../features/db";
import { useState, useEffect } from "react";
import { usePC } from "../features/useMediaQuery";

export default function Home({ category }: { category: Category }) {
  const pc = usePC()
  const [blogs, setBlogs] = useState<Blog[]>([])
  
  useEffect(() => {
    (async () => {
      const blogs = await getBlogsByCategory(category.id, 4)
      setBlogs(blogs)
    })()
  }, [])
  
  return pc ? (
    <div className="col center g-40">
      <div className="w-100 col g-25">
        <h2 className="b48 lh-2 border t-left" style={{ color: category.color }}>{category.name}</h2>
        <RowCards blogs={blogs} />
      </div>
      <RoundedButton href={`/categories/${category.id}`} text="More..." color={category.color} />
    </div>
  ) : (
    <div className="col center g-40">
      <div className="w-100 col g-25">
        <h2 className="b24 lh-2 border t-left" style={{ color: category.color }}>{category.name}</h2>
        <RowCards blogs={blogs} />
      </div>
      <RoundedButton href={`/categories/${category.id}`} text="More..." color={category.color} />
    </div>
  )
}
