import L from "leaflet"
import { Marker, useMap, useMapEvents } from "react-leaflet"
import { usePC } from "../features/useMediaQuery"

export function MapEvents({ 
  onClick, onDoubleClick, onZoomEnd 
}: { 
  onClick?: (location: L.LatLng) => void,
  onDoubleClick?: (location: L.LatLng) => void,
  onZoomEnd?: (zoom: number) => void ,
}) {
  const map = useMap()
  const mapEvents = useMapEvents({
    click: (e) => {
      onClick && onClick(e.latlng)
    },
    dblclick: (e) => {
      map.panTo(e.latlng)
      onDoubleClick && onDoubleClick(e.latlng)
    },
    zoomend: () => {
      onZoomEnd && onZoomEnd(mapEvents.getZoom())
    },
  })
  return null
}

export function BlogMarker({ blog, selected = false, onClick }: {
  blog: Blog,
  selected?: boolean,
  onClick?: () => void,
}) {
  const pc = usePC()
  const map = useMap()
  const location = blog.location
  if(location != undefined) {
    const latLng = new L.LatLng(location.lat, location.lng)
    const w = (pc ? 73 : 48) * (selected ? 2.5 : 1)
    const h = (pc ? 108 : 76)  * (selected ? 2.5 : 1)
    const color = blog.category?.color ?? 'var(--theme)'
    const url = blog?.eyecatch?.url ?? '/no_image.svg'
    const icon = L.divIcon({
      html: `
        <svg xmlns="http://www.w3.org/2000/svg" width="${w}" height="${h}" viewBox="0 0 77 119" fill="none">
          <path d="M45.9744 82.2249L46.3043 80.7954L44.8532 81.0108C40.727 81.6234 36.273 81.6234 32.1468 81.0108L30.6917 80.7948L31.0262 82.2274L37.5647 110.227L38.5443 114.422L39.5129 110.225L45.9744 82.2249Z" fill="${color}" stroke="white" stroke-width="2" stroke-miterlimit="5.75877"/>
          <path d="M38.5 76C59.2107 76 76 59.2107 76 38.5C76 17.7893 59.2107 1 38.5 1C17.7893 1 1 17.7893 1 38.5C1 59.2107 17.7893 76 38.5 76Z" fill="${color}" stroke="white" stroke-width="2" />
          <clipPath id="clip" transform="">
            <circle cx="38.5" cy="38.5" r="33.5" />
          </clipPath>
          <image x="0" y="0" height="77" xlink:href="${url}" clip-path="url(#clip)"/>
        </svg>`,
      iconSize: [0, 0],
      iconAnchor: [w / 2, h]
    })
    return <Marker position={latLng} icon={icon} eventHandlers={{
      click: () => {
        map.panTo(latLng)
        onClick && onClick()
      }
    }} />
  } else {
    return null
  }
}

export function CurrentMarker({ location }: { location: L.LatLng }) {
  const icon = L.divIcon({
    html: `<img src="/current_icon.svg" width="36" height="36" alt="current location" />`,
    iconSize: [0, 0],
    iconAnchor: [18, 18]
  })
  return <Marker position={location} icon={icon} />
}


export function FocusedMarker({ location }: { location: L.LatLng }) {
  const pc = usePC()
  const icon = L.divIcon({
    html: `<img src="/focused_icon.svg" width="${pc ? 88 : 44}" height="${pc ? 88 : 44}" alt="focused location" />`,
    iconSize: [0, 0],
    iconAnchor: [0, pc ? 88 : 44]
  })
  return <Marker position={location} icon={icon} />
}
