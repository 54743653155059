import { getBlogByID } from "../features/db"
import { useEffect, useState } from "react"
import parse from "html-react-parser"
import { Link, useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import SingleMap from "../components/SingleMap"
import { usePC } from "../features/useMediaQuery"

export default function Home() {
  const pc = usePC()
  const [blog, setBlog] = useState<Blog|undefined>(undefined)
  const [notFound, setNotFound] = useState(false)
  const { id } = useParams<{ id: string }>()
  
  useEffect(() => {
    if (id != undefined) {
      (async () => {
        const blog = await getBlogByID(id)
        setBlog(blog)
        setNotFound(blog == undefined)
      })()
    }
  }, [id])

  if(blog != undefined) {
    const title = blog.title ?? 'No title'
    const date = new Date(blog.publishedAt)
    const excerpt = blog.content?.replaceAll(/<[^<>]*>/g, '')?.slice(0, 100) ?? ''
    const content = parse(blog.content ?? '')
    const color = blog.category?.color ?? 'var(--theme)'
    const url = blog.eyecatch?.url ?? '/no_image.svg'
    const address = blog.location?.address ?? ''
    const tags = blog.tags?.split(',')
    return <div className="blog-container col center g-35">
      <div className="w-100 col left g-35">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={excerpt} />
        </Helmet>
        <SingleMap blog={blog} />
        <h1 className="b48" style={{ color: color }}>{title}</h1>
        <div className="row bottom g-20">
          <a className="accent-stroke pointer up" href={`https://www.google.co.jp/maps/place/${encodeURIComponent(address)}`} target="_blank" style={{ width: 220, height: 45 }}>
            <div className="full row center g-10">
              <img src="/pin_icon.svg" width="18" height="25" alt="check by google map" />
              <div className="accent b16">Googleマップで見る</div>
            </div>
          </a>
          <div className="r14 secondary">{date.toLocaleDateString()}</div>
        </div>
        <Link className="w-100" to={`/blogs/${blog.id}`}>
          <img className="eyecatch" src={url} alt={title} />
        </Link>
        <div className="blog-content">{content}</div>
        <div className="row start wrap g-10">
          {
            tags?.map((tag) => <div className="theme-stroke r10" style={{ padding: 4 }}>#{tag}</div>)
          }
        </div>
      </div>
      <a className="w-100 accent-stroke pointer up" href={`https://www.google.co.jp/maps/place/${encodeURIComponent(address)}`} target="_blank" style={{ maxWidth: 440, height: 80, padding: 10 }}>
        <div className="full row center g-10">
          <img src="/pin_icon.svg" width={pc ? 28 : 20} height={pc ? 38 : 28} alt="check by google map" />
          <div className="accent b16">Googleマップでこのエッセイの場所を見る</div>
        </div>
      </a>
    </div>
  } else if(notFound) {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div>記事が見つかりませんでした</div>
      </>
    )
  } else {
    return null
  }
}
